import qs from 'qs';
import { push } from 'connected-react-router';
import store from 'resources/store';

import { success, error } from './flash';
import { filterNulls } from '../utils';
import { loadResellerOffers } from './dealers';

if (process.env.NODE_ENV === 'test') {
  window.ENV = {
    BACKEND_BASE_URL: 'fake',
    AUTH0_DOMAIN: 'fake',
    AUTH0_CLIENT_ID: 'fake',
    AUTH0_AUDIENCE: 'fake',
    BASENAME: '/fake'
  };
}

const BASE_URL = window.ENV.BACKEND_BASE_URL;

export function getBaseUrl() {
  return BASE_URL;
}

function generateUrl(path) {
  return /^https?:\/\//.test(path) ? path : BASE_URL + path;
}

export const getAuthToken = () => store.getState().auth.token;

export function authOpts(accessToken = getAuthToken(), options) {
  const opts = options || {};

  return {
    ...opts,
    headers: {
      ...(opts.headers || {}),
      ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
    },
  };
}

function encodeQuery(query) {
  return query ? `?${qs.stringify(query)}` : '';
}

function filterNullCompanies(offer) {
  return {
    ...offer,
    companies: offer.companies.filter(({ id }) => id !== null),
  };
}


export async function goFetch(path, options) {
  try {
    const response = await fetch(path, authOpts(getAuthToken(), options));

    if (response.status >= 400) {
      const error = new Error(`${options.method || 'GET'} => [${response.status}] ${response.statusText}`);

      error.name = 'Request failed';
      error.status = response.status;
      const contentType = response.headers.get('content-type');
      const isJSON = contentType && contentType.match(/^application\/json$/);

      error.body = await (isJSON ? response.json() : response.text());
      throw error;
    }

    return response;
  } catch (e) { // also show fetch errors
    e.show = true;
    throw e;
  }
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function rawGET(accessToken, path, options, query) {
  return fetch(generateUrl(path) + encodeQuery(query), authOpts(accessToken, options));
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function GET(accessToken, path, options, query) {
  const response = await goFetch(generateUrl(path) + encodeQuery(query), authOpts(accessToken, options));

  return await response.json();
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function GET_PAGE(accessToken, path, options, query) {
  const response = await goFetch(generateUrl(path) + encodeQuery(query), authOpts(accessToken, options));

  return [
    await response.json(),
    {
      page: parseInt(response.headers.get('x-page'), 10),
      perPage: parseInt(response.headers.get('x-per-page'), 10),
      total: parseInt(response.headers.get('x-total'), 10),
    },
  ];
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function PUT(accessToken, path, body) {
  const response = await goFetch(generateUrl(path), authOpts(accessToken, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
    },
  }));

  return response;
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function PATCH(accessToken, path, body) {
  const response = await goFetch(generateUrl(path), authOpts(accessToken, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
    },
  }));

  return response;
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function POST(accessToken, path, body) {
  const response = await goFetch(generateUrl(path), authOpts(accessToken, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'content-type': 'application/json' },
  }));

  return response;
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export function rawPOST(accessToken, path, options) {
  return fetch(generateUrl(path), authOpts(accessToken, {
    ...options,
    method: 'POST',
  }));
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function DELETE(accessToken, path, body) {
  const options = { method: 'DELETE' };

  if (body) {
    options.body = JSON.stringify(body);
    options.headers = {
      'content-type': 'application/json',
    };
  }
  const response = await goFetch(generateUrl(path), authOpts(accessToken, options));

  return response;
}

/**
 * @param {string=} accessToken - Deprecated. Access token takes from store immediately
 */
export async function loadSchema(accessToken, module, collection) {
  const schema = await GET(accessToken, `/${module}/schemas/${collection}`);

  return {
    type: 'SCHEMA_LOADED', module, collection, schema,
  };
}

export function loadOffers(accessToken, requestedQuery) {
  return async (dispatch) => {
    const query = { page: 1, ...requestedQuery };

    dispatch({ type: 'OFFERS_LOADING', query });
    const [offers, pageInfo] = await GET_PAGE(accessToken, '/offers/offers', {}, query);

    dispatch({
      type: 'OFFERS_LOADED', query, offers, pageInfo,
    });
  };
}

export async function loadOffer(accessToken, id) {
  const offer = await GET(accessToken, `/offers/offers/${id}`);

  return { type: 'OFFER_LOADED', id, offer: offer.code === 'NotFound' ? null : offer };
}

export function updateOffer(accessToken, id, offer) {
  return async (dispatch) => {
    await PUT(accessToken, `/offers/offers/${id}`, filterNullCompanies(offer));
    dispatch(loadOffer(accessToken, id));
    dispatch(success('Offer has been updated.'));
  };
}

export function patchOffer(accessToken, id, patch) {
  return async (dispatch) => {
    try {
      await PATCH(accessToken, `/offers/offers/${id}`, patch);
      await dispatch(loadOffer(accessToken, id));
      dispatch(success('Offer has been updated.'));
    } catch (err) {
      dispatch(error(err.body?.message || 'Something going wrong'));
    }
  };
}

export function loadProductsOffers(requestedQuery) {
  return async (dispatch) => {
    const query = { ...requestedQuery };

    dispatch({ type: 'PRODUCTS_OFFERS_LOADING', query });
    
    const productsOffers = await GET(undefined, '/offers/offers/public', undefined, query);

    dispatch({
      type: 'PRODUCTS_OFFERS_LOADED', query, productsOffers,
    });
  };
}

export function loadCompanyOffers(accessToken, companyId) {
  return async (dispatch) => {
    const offers = await GET(accessToken, '/offers/offers/', undefined, {
      filter: {
        state: 'template',
        company_id: companyId,
      },
    });

    // offers.forEach(offer => dispatch(loadOffer(accessToken, offer.id)))
    dispatch({ type: 'COMPANY_OFFERS_LOADED', id: companyId, offers });
  };
}

export function addCompanyToOffer(accessToken, offerId, key, companyId) {
  return async (dispatch) => {
    await POST(accessToken, '/offers/_super/company_offers', {
      offer_id: offerId,
      company_id: companyId,
      key,
    });
    dispatch(loadOffer(accessToken, offerId));
    dispatch(success('Company has been added'));
  };
}

export function addResellerToOffer({offerId, resellerProducts, resellerId}) {
  return async (dispatch) => {
    try {
      await POST(undefined, '/offers/_super/reseller_offers', {
        offer_id: offerId,
        reseller_id: resellerId,
        reseller_products:resellerProducts,
      });
      const query = {
        filter: {
          offer_id: offerId,
        },
      };
    
      dispatch(loadResellerOffers(query));
      dispatch(success('Reseller has been added'));
    } catch (e) {
      dispatch(error("Something went wrong. Please try again after some time"));
    }
  };
}

export function removeCompanyFromOffer(accessToken, offerId, key, companyId) {
  return async (dispatch) => {
    const companyOffers = await GET(accessToken, '/offers/_super/company_offers', {}, {
      filter: {
        company_id: companyId,
        offer_id: offerId,
      },
    });
    const record = companyOffers.find(co => co.key === key);
    const id = record && record.id;

    if (id) {
      await DELETE(accessToken, `/offers/_super/company_offers/${id}`);
      dispatch(loadOffer(accessToken, offerId));
      dispatch(success('Company has been removed'));
    }
  };
}

export function removeResellerFromOffer(offerId ,id) {
  return async (dispatch) => {
      await DELETE(undefined, `/offers/_super/reseller_offers/${id}`);
      const query = {
        filter: {
          offer_id: offerId,
        },
      };
    
      dispatch(loadResellerOffers(query));
      dispatch(success('Reseller has been removed'));
    };
}

export function createOffer(accessToken, offer) {
  return async (dispatch) => {
    const response = await POST(accessToken, '/offers/offers', offer);
    const { id } = await response.json();

    dispatch({ type: 'OFFER_CREATED', id });
    dispatch(success(`Offer has been created: ${id}`));
    dispatch(push(`/offers/show/${id}`));
  };
}

export function makeTemplateFromOffer(accessToken, sourceId, params = {}) {
  if (!params.documents) {
    params.documents = []; // required by api
  }

  return async (dispatch) => {
    const response = await POST(accessToken, `/offers/offers/${sourceId}/make-template`, params);
    const { id } = await response.json();

    dispatch({ type: 'OFFER_TO_TEMPLATE', id });
    dispatch(success(`Offer has been cloned: ${sourceId} -> ${id}`));
    dispatch(push(`/offers/show/${id}`));
  };
}

export function cloneOffer(accessToken, sourceId, params) {
  return async (dispatch) => {
    const response = await POST(accessToken, `/offers/offers/${sourceId}/clone`, params);
    const { id } = await response.json();

    dispatch({ type: 'OFFER_CLONED', id });
    dispatch(success(`Offer has been cloned: ${sourceId} -> ${id}`));
    dispatch(push(`/offers/show/${id}`));
  };
}

export function publishOffer(accessToken, id) {
  return async (dispatch) => {
    const response = await rawPOST(accessToken, `/offers/offers/${id}/publish`, {});

    if (response.status === 200) {
      dispatch(loadOffer(accessToken, id));
      dispatch(success('Offer has been published'));
    } else {
      dispatch({ type: 'OFFER_PUBLISH_FAILED', id, errors: await response.json() });
    }
  };
}

export function clearOfferPublishErrors(id) {
  return { type: 'OFFER_CLEAR_PUBLISH_ERRORS', id };
}

export function destroyOffer(accessToken, id) {
  return async (dispatch) => {
    try {
      await DELETE(accessToken, `/offers/offers/${id}`);
      dispatch({ type: 'OFFER_DELETED', id });
      dispatch(success(`Offer has been deleted ${id}`));
      dispatch(push('/offers'));
    } catch (err) {
      dispatch(error(err));
    }
  };
}

export function undeleteOffer(accessToken, id) {
  return async (dispatch) => {
    await POST(accessToken, `/offers/offers/${id}/undelete`);
    dispatch({ type: 'OFFER_UNDELETED', id });
    dispatch(success(`Offer has been restored ${id}`));
    dispatch(push('/offers'));
  };
}

export function loadDocuments(accessToken, requestedQuery) {
  return async (dispatch) => {
    try {
      const query = { page: 1, ...requestedQuery };

      dispatch({ type: 'DOCUMENTS_LOADING', query });
      const [documents, pageInfo] = await GET_PAGE(accessToken, '/documents/_super/documents', {}, query);

      dispatch({
        type: 'DOCUMENTS_LOADED', query, documents, pageInfo,
      });
    } catch (error) {
      dispatch({ type: 'DOCUMENTS_LOADING_FAILED', error });

    }
    
  };
}

export async function loadDocument(accessToken, id) {
  const document = await GET(accessToken, `/documents/_super/documents/${id}`);

  return { type: 'DOCUMENT_LOADED', id, document };
}

export function patchDocument(accessToken, id, patch) {
  return async (dispatch) => {
    await PATCH(accessToken, `/documents/_super/documents/${id}`, patch);
    dispatch(loadDocument(accessToken, id));
    dispatch(success('Document has been updated.'));
  };
}

export function destroyDocument(accessToken, id, currentQuery) {
  return async (dispatch) => {
    await DELETE(accessToken, `/documents/_super/documents/${id}`);
    dispatch(loadDocuments(accessToken, currentQuery || {}));
  };
}

export function documentViewUrl(id) {
  return `${BASE_URL}/documents/documents/view/${id}`;
}

export const generateSignedUrlById = async (id) => {
  const { signedUrl } = await GET(undefined, `/documents/documents/signed-url/${id}`);

  return signedUrl;
};

export const generateSignedUrlByHash = async (hash) => {
  const { signedUrl } = await GET(undefined, `/documents/documents/signed-url/by-hash/${hash}`);

  return signedUrl;
};

export function companyLogoUrl(companyId) {
  return `${BASE_URL}/offers/companies/${companyId}/logo`;
}

export function loadCompanies(accessToken, { filter, ...requestedQuery }, options = {}) {
  return async (dispatch) => {
    const query = { page: 1, filter: { is_deleted: false, ...filter }, ...requestedQuery };

    if (options.showArchived) {
      delete query.filter.is_deleted;
    }

    dispatch({ type: 'COMPANIES_LOADING', query });
    const [companies, pageInfo] = await GET_PAGE(accessToken, '/offers/companies', {}, { ...query, select: 'id,name,legal_name,utility_types,documents,is_deleted,count_published_offers,count_offers,count_channels,created_at,updated_at' });

    dispatch({
      type: 'COMPANIES_LOADED', query, companies, pageInfo,
    });
  };
}

export function createCompany(accessToken, company) {
  return async (dispatch) => {
    const response = await POST(accessToken, '/offers/companies', company);
    const { id } = await response.json();

    dispatch(success(`Company has been created: ${company.name}`));
    dispatch(push(`/companies/show/${id}`));
  };
}

export async function loadCompany(accessToken, id) {
  const company = await GET(accessToken, `/offers/companies/${id}`);

  return { type: 'COMPANY_LOADED', id, company };
}

export function updateCompany(accessToken, id, company) {
  return async (dispatch) => {
    await PUT(accessToken, `/offers/companies/${id}`, company);
    dispatch(success('Company has been updated.'));
    dispatch(loadCompany(accessToken, id));
  };
}

export function patchCompany(accessToken, id, patch) {
  return async (dispatch) => {
    try {
      await PATCH(accessToken, `/offers/companies/${id}`, patch);
      dispatch(loadCompany(accessToken, id));
      dispatch(success('Company has been updated.'));
    } catch (err) {
      dispatch(error(err.body?.message || 'Something going wrong'));
    }
  };
}

export function archiveCompany(id) {
  return async (dispatch) => {
    try {
      await DELETE(undefined, `/offers/companies/${id}`);

      dispatch(push('/companies/show'));

      dispatch(success('Company has been archived'));
    } catch (err) {
      dispatch(error(err));
    }
  };
}

export function restoreCompany(companyId) {
  return async (dispatch) => {
    try {
      await POST(undefined, `/offers/companies/${companyId}/undelete`);

      dispatch(push('/companies/show'));

      dispatch(success('Company has been restored'));
    } catch (err) {
      dispatch(error(err));
    }
  };
}

export function autocompleteCompanies(accessToken, filter, options = {}) {
  return async (dispatch) => {
    const query = { filter: { is_deleted: false, ...filter } };

    if (options.showArchived) {
      delete query.filter.is_deleted;
    }

    if (!query.filter.utility_types) {
      delete query.filter.utility_types;
    }

    const result = await GET(accessToken, '/offers/companies', {}, query);

    dispatch({ type: 'AUTOCOMPLETE', collection: 'companies', suggestions: result });
  };
}

export function autocompleteOffers(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/offers/offers/', {}, { filter });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'offers', suggestions: result });
  };
}

export function autocompleteZipcodeGroups(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/properties/zipcodegroups', {}, { filter });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'zipcodegroups', suggestions: result });
  };
}

export function autocompleteDealers(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/users/dealers', {}, { filter });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'dealers', suggestions: result });
  };
}

export function autocompleteProperties(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/properties/properties', {}, { filter });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'properties', suggestions: result });
  };
}

export function autocompleteUnits(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/properties/properties', {}, { filter: { is_unit: true, ...filter } });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'units', suggestions: result });
  };
}

export function autocompletePlaceLists(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/properties/placeList', {}, { filter });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'placeLists', suggestions: result });
  };
}

export function autocompletePlaces(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/properties/places', {}, { filter });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'places', suggestions: result });
  };
}

export function autocompleteImportSources(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/imports/import_sources', {}, { filter, select: 'id,name' });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'importSources', suggestions: result });
  };
}

export function autocompletePersons(accessToken, filter) {
  return async (dispatch) => {
    const result = await GET(accessToken, '/people/persons', {}, { filter });

    dispatch({ type: 'AUTOCOMPLETE', collection: 'persons', suggestions: result });
  };
}


export function clearAutocomplete(accessToken, collection) {
  return { type: 'AUTOCOMPLETE', collection, suggestions: [] };
}

export function loadEmailTemplates(accessToken, requestedQuery) {
  return async (dispatch) => {
    const query = { page: 1, ...requestedQuery };

    dispatch({ type: 'EMAIL_TEMPLATES_LOADING', query });
    const emailTemplates = await GET(accessToken, '/email/_super/templates', {}, query);

    dispatch({ type: 'EMAIL_TEMPLATES_LOADED', query, emailTemplates });
  };
}

export function createEmailTemplate(accessToken, emailTemplate) {
  return async (dispatch) => {
    const response = await POST(accessToken, '/email/_super/templates', emailTemplate);
    const { id } = await response.json();

    dispatch({ type: 'EMAIL_TEMPLATE_CREATED', id });
    dispatch(success(`Email Template has been created: ${id}`));
    dispatch(push('/contact/email-templates'));
  };
}

export function destroyEmailTemplate(accessToken, id) {
  return async (dispatch) => {
    const template = encodeURIComponent(id);

    await DELETE(accessToken, `/email/_super/templates/${template}`);

    dispatch({ type: 'EMAIL_TEMPLATE_DELETED', id });
    dispatch(success(`Email Template has been deleted ${id}`));
    dispatch(push('/contact/email-templates'));
  };
}

export async function loadEmailTemplate(accessToken, id) {
  const template = encodeURIComponent(id);
  const emailTemplate = await GET(accessToken, `/email/_super/templates/${template}`);

  return { type: 'EMAIL_TEMPLATE_LOADED', id, emailTemplate };
}

export async function previewEmailTemplate(accessToken, id, format, variables) {
  try {
    const template = encodeURIComponent(id);
    const response = await POST(accessToken, `/email/preview/${template}/${format}`, variables);

    return await response.text();
  } catch (err) {
    return '';
  }
}

export function updateEmailTemplate(accessToken, id, emailTemplate) {
  return async (dispatch) => {
    const template = encodeURIComponent(id);
    const result = await PUT(accessToken, `/email/_super/templates/${template}`, emailTemplate);

    dispatch({
      type: 'EMAIL_TEMPLATE_UPDATED', id, emailTemplate, result,
    });
    dispatch(success('Email Template has been updated.'));
    dispatch(push('/contact/email-templates'));
  };
}

export async function loadActivation(accessToken, id) {
  const activation = await GET(accessToken, `/activation/activations/${id}`);

  return { type: 'ACTIVATION_LOADED', id, activation };
}

export function destroyActivation(accessToken, id) {
  return async (dispatch) => {
    await DELETE(accessToken, `/activation/activations/${id}`);
    dispatch({ type: 'ACTIVATION_DELETED', id });
    dispatch(success(`Activation has been deleted ${id}`));
    dispatch(push('/activations'));
  };
}

export function patchActivation(accessToken, id, patch) {
  return async (dispatch) => {
    await PATCH(accessToken, `/activation/activations/${id}`, patch);
    dispatch(loadActivation(accessToken, id));
    dispatch(success('Activation has been updated'));
  };
}

export async function transitionManualActivation(accessToken, id, state, payload) {
  return async (dispatch) => {
    await POST(accessToken, `/activation/manual-activations/transition/${id}/${state}`, payload);
    dispatch(success('Activation status has been updated'));
    dispatch(loadActivation(accessToken, id));
  };
}

export async function updateActivationPayload(accessToken, id, payload, notes) {
  await POST(accessToken, `/activation/manual-activations/update-payload/${id}`, { payload, notes });

  return loadActivation(accessToken, id);
}

export async function addActivationNote(accessToken, id, note, queryFlags) {
  await POST(accessToken, `/activation/manual-activations/take-note/${id}?${queryFlags ? qs.stringify(queryFlags) : ''}`, note);

  return loadActivation(accessToken, id);
}

export function activationCsvUrl(accessToken, query) {
  return `${BASE_URL}/activation/export-csv?${qs.stringify({ ...query, access_token: accessToken })}`;
}

export function loadRecommendations(accessToken, requestedQuery) {
  return async (dispatch) => {
    const query = { page: 1, ...requestedQuery };

    dispatch({ type: 'RECOMMENDATIONS_LOADING', query });
    const recommendations = await GET(accessToken, '/offers/_super/recommendations', {}, query);

    dispatch({ type: 'RECOMMENDATIONS_LOADED', query, recommendations });
  };
}

export function createRecommendation(accessToken, recommendation) {
  return async (dispatch) => {
    await POST(accessToken, '/offers/_super/recommendations', recommendation);
    dispatch(loadRecommendations(accessToken, {}));
  };
}

export function updateRecommendation(accessToken, id, recommendation) {
  return async (dispatch) => {
    await PUT(accessToken, `/offers/_super/recommendations/${id}`, filterNulls(recommendation));
    dispatch(loadRecommendations(accessToken, {}));
  };
}

export function destroyRecommendation(accessToken, id) {
  return async (dispatch) => {
    await DELETE(accessToken, `/offers/_super/recommendations/${id}`);
    dispatch(loadRecommendations(accessToken, {}));
  };
}

export function loadCustomFields(accessToken, requestedQuery) {
  return async (dispatch) => {
    const query = { page: 1, ...requestedQuery };

    dispatch({ type: 'CUSTOM_FIELDS_LOADING', query });
    const customFields = await GET(accessToken, '/offers/custom_fields', {}, query);

    dispatch({ type: 'CUSTOM_FIELDS_LOADED', query, customFields });
  };
}

export function loadCustomField(accessToken, id) {
  return async (dispatch) => {
    const customField = await GET(accessToken, `/offers/custom_fields/${id}`);

    dispatch({ type: 'CUSTOM_FIELD_LOADED', id, customField });
  };
}

export function createCustomField(accessToken, customField) {
  return async (dispatch) => {
    await POST(accessToken, '/offers/custom_fields', customField);
    dispatch(loadCustomFields(accessToken, 
      customField.parent_id ? { filter: { parent_id: customField.parent_id } } : { filter: { root: true } }));
    dispatch(loadCustomFieldTree(accessToken));
  };
}

export function updateCustomField(accessToken, id, customField) {
  return async (dispatch) => {
    await PUT(accessToken, `/offers/custom_fields/${id}`, customField);
    dispatch(loadCustomFields(accessToken, 
      customField.parent_id ? { filter: { parent_id: customField.parent_id } } : { filter: { root: true } }));
    dispatch(loadCustomFieldTree(accessToken));
  };
}

export function destroyCustomField(accessToken, id, parentId) {
  return async (dispatch) => {
    await DELETE(accessToken, `/offers/custom_fields/${id}`);
    dispatch(loadCustomFields(accessToken, 
      parentId ? { filter: { parent_id: parentId } } : { filter: { root: true } }));
    dispatch(loadCustomFieldTree(accessToken));
  };
}

export function loadCustomFieldReferences(accessToken, id) {
  return async (dispatch) => {
    dispatch({ type: 'CUSTOM_FIELD_REFERENCES_LOADING', id });
    const references = await GET(accessToken, `/offers/custom_fields/${id}/references`);

    dispatch({ type: 'CUSTOM_FIELD_REFERENCES_LOADED', id, references });
  };
}

export function loadCustomFieldTree(accessToken) {
  return async (dispatch) => {
    const tree = await GET(accessToken, '/offers/custom_field_tree');

    dispatch({ type: 'CUSTOM_FIELD_TREE_LOADED', tree });
  };
}

export function loadAddons(accessToken, companyId) {
  return async (dispatch) => {
    dispatch({ type: 'ADDONS_LOADING', companyId });
    const addons = await GET(accessToken, '/offers/_super/addons', {}, { filter: { company_id: companyId } });

    dispatch({ type: 'ADDONS_LOADED', companyId, addons });
  };
}

export function createAddon(accessToken, addon) {
  return async (dispatch) => {
    await POST(accessToken, '/offers/_super/addons', addon);
    dispatch(loadAddons(accessToken, addon.company_id));
  };
}

export function updateAddon(accessToken, id, addon) {
  return async (dispatch) => {
    await PUT(accessToken, `/offers/_super/addons/${id}`, filterNulls(addon, ['min_quantity', 'max_quantity']));
    dispatch(loadAddons(accessToken, addon.company_id));
  };
}

export function removeAddon(accessToken, companyId, addonId) {
  return async (dispatch) => {
    await DELETE(accessToken, `/offers/_super/addons/${addonId}`);
    dispatch(loadAddons(accessToken, companyId));
  };
}

export function loadOfferAddons(accessToken, offerId) {
  return async (dispatch) => {
    dispatch({ type: 'OFFER_ADDONS_LOADING', offerId });
    const offerAddons = await GET(accessToken, '/offers/_super/offer_addons', {}, { filter: { offer_id: offerId } });

    dispatch({ type: 'OFFER_ADDONS_LOADED', offerId, offerAddons });
  };
}

export function createOfferAddons(accessToken, offerId, addonIds) {
  return async (dispatch) => {
    await Promise.all(addonIds.map(
      addonId => POST(accessToken, '/offers/_super/offer_addons', {
        offer_id: offerId,
        addon_id: addonId.id,
      }),
    ));
    dispatch(loadOfferAddons(accessToken, offerId));
  };
}

export function removeOfferAddon(accessToken, offerId, offerAddonId) {
  return async (dispatch) => {
    await DELETE(accessToken, `/offers/_super/offer_addons/${offerAddonId}`);
    dispatch(loadOfferAddons(accessToken, offerId));
  };
}

export function createAddonForOffer(accessToken, addon, offerId) {
  return async (dispatch) => {
    const response = await POST(accessToken, '/offers/_super/addons', addon);
    const { id } = await response.json();

    dispatch(loadAddons(accessToken, addon.company_id));
    await POST(accessToken, '/offers/_super/offer_addons', { addon_id: id, offer_id: offerId });
    dispatch(loadOfferAddons(accessToken, offerId));
  };
}

export function sendEmail(accessToken, params, attachments) {
  return async (dispatch) => {
    dispatch({ type: 'EMAIL_PREVENT_SPAM' });
    let response;

    if (attachments) {
      const formData = new FormData();

      formData.append('data', JSON.stringify(params));
      attachments.forEach(({ filename, file }, i) => {
        console.log('appending file', file);
        formData.append(`attachment_${i}`, file, filename);
      });
      response = await rawPOST(accessToken, '/email/send', {
        body: formData,
      });
    } else {
      response = await POST(accessToken, '/email/send', params);
    }
    const { id } = await response.json();

    dispatch({ type: 'EMAIL_SENT', id });
    dispatch(success(`Email sent: ${id}`));
    setTimeout(() => dispatch({ type: 'EMAIL_RESET_PREVENT_SPAM' }), 2000);
  };
}

export function loadEmails(accessToken, requestedQuery) {
  return async (dispatch) => {
    const query = { page: 1, ...requestedQuery };

    dispatch({ type: 'EMAILS_LOADING', query });
    const [emails, pageInfo] = await GET_PAGE(accessToken, '/email/emails', {}, query);

    dispatch({
      type: 'EMAILS_LOADED', query, emails, pageInfo,
    });
  };
}

export async function loadEmail(accessToken, id) {
  const email = await GET(accessToken, `/email/emails/${id}`);

  return { type: 'EMAIL_LOADED', id, email };
}

export async function loadEmailsByMeta(accessToken, key, value) {
  const emails = await GET(accessToken, '/email/emails', {}, {
    filter: {
      meta: JSON.stringify({ [key]: value }),
    },
  });

  return {
    type: 'EMAILS_BY_META_LOADED', key, value, emails,
  };
}

export async function loadEmailsByMetaContains(accessToken, key, value) {
  const emails = await GET(accessToken, '/email/emails', {}, {
    filter: {
      metaContains: { [key]: value },
    },
  });

  return {
    type: 'EMAILS_BY_META_LOADED', key, value, emails,
  };
}

export async function activationCreateAffiliate(accessToken, id) {
  await POST(accessToken, `/activation/activations/${id}/create-affiliate`);

  return loadActivation(accessToken, id);
}

export function startFulfillment(accessToken, id, payload) {
  return async (dispatch) => {
    const response = await POST(accessToken, `/activation/activations/${id}/fulfill`, payload);

    const result = await response.json();

    if (result.ok) {
      dispatch(success('Activation is fulfilled successfully'));
    } else {
      dispatch(error('Failed to fulfill activation. Please make sure that tenant\'s data is valid and dealer settings are correct'));
    }

    dispatch(loadActivation(accessToken, id));
  };
}

export function loadSignupFieldsByCompany(accessToken, companyId) {
  return async (dispatch) => {
    const [{ fields }] = await GET(accessToken, `/offers/signup_fields/by_company/${companyId}`);

    dispatch({ type: 'SIGNUP_FIELDS_LOADED', companyId, fields });
  };
}

export function loadSignupAndActivationFieldsByCompany(accessToken, companyId) {
  return async (dispatch) => {
    const fields = await GET(accessToken, `/offers/companies/${companyId}/signup-and-activation-fields`);

    dispatch({ type: 'SIGNUP_AND_ACTIVATION_FIELDS_LOADED', companyId, fields });
  };
}

export function clearSignupFields(companyId) {
  return { type: 'CLEAR_SIGNUP_FIELDS', companyId };
}
